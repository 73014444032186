import Image1 from "../../assets/project1.jpg";
import Image2 from "../../assets/project2.jpg";
import Image3 from "../../assets/project3.jpg";
import Image4 from "../../assets/maktSegt.jpg";
import Image5 from "../../assets/employee.jpg";
import Image6 from "../../assets/mlrecom.jpg";
import Image7 from "../../assets/sent.jpg";
import Image8 from "../../assets/project8.jpg";
import Image9 from "../../assets/chest.jpg";
import Image10 from "../../assets/ff.jpeg";
import Image11 from "../../assets/project9.png";

const data = [
  {
    id: 1,
    category: "Data Science",
    image: Image4,
    title: "Customer-Market-Segmentation-Using-Machine-Learning",
    desc: "This project focuses on using K-means clustering and PCA to segment customers of a New York City bank based on their spending habits, enabling targeted marketing and improved customer engagement.",
    details: "http://projectdetails.ridhampuri.in/",
    github:
      "https://github.com/ritz-bot/Customer-Market-Segmentation-Using-Machine-Learning.git",
  },
  {
    id: 2,
    category: "Data Science",
    image: Image5,
    title: "Employee Attrition Prediction Project",
    desc: "Predicting employee attrition is vital for any business. This project identifies employees likely to leave and explores reasons behind attrition, aiming to improve retention through predictive modeling",
    details: "http://projectdetails.ridhampuri.in/",
    github:
      "https://github.com/ritz-bot/Employee-Attrition-Prediction-Project.git",
  },

  {
    id: 3,
    category: "ML & DL",
    image: Image11,
    title: "Face Mask Detection",
    desc: "The Face Mask Detection project uses ML and DL to automatically identify mask-wearing individuals in real-time or static images, enhancing public safety and compliance with health guidelines.",
    details: "http://projectdetails.ridhampuri.in/",
    github:
      "https://github.com/ritz-bot/Recognition-of-facial-mask-covering-using-Machine-learning-algorithms-and-frameworks-like-Tensorflow.git",
  },

  {
    id: 4,
    category: "Data Science",
    image: Image9,
    title:
      "Automated-Detection-and-Classification-of-Chest-Diseases-Using-Deep-Learning ",
    desc: "This project leverages deep learning to detect and classify chest diseases. It guides you step-by-step from understanding the problem and business case to building and evaluating a deep learning model.",
    details: "http://projectdetails.ridhampuri.in/",
    github:
      "https://github.com/ritz-bot/Automated-Detection-and-Classification-of-Chest-Diseases-Using-Deep-Learning.git",
  },
  {
    id: 5,
    category: "NLP",
    image: Image6,
    title: "ML-Paper-Recommender-Application",
    desc: "a Streamlit application that helps users find machine learning papers related to specific methodologies. The app leverages various Documents to search for academic papers and provides an intuitive interface for easy access",
    details: "http://projectdetails.ridhampuri.in/",
    github: "https://github.com/ritz-bot/ML-Paper-Recommender-Application.git",
  },
  {
    id: 6,
    category: "NLP",
    image: Image7,
    title: "Sentiment-Analysis-in-Public-Relations---Case-Study",
    desc: "NLP case study: Automated sentiment analysis of customer reviews and social media posts for public relations.",
    details: "http://projectdetails.ridhampuri.in/",
    github:
      "https://github.com/ritz-bot/Sentiment-Analysis-in-Public-Relations---Case-Study.git",
  },
  
];

export default data;
